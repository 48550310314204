.clients__header {
    margin-top: 4em;
}

.adminList, .adminList__item {
    @include listReset();
    display: grid;
    grid-template-columns: repeat(3, 1fr) 3em;
}

.adminList--clients, .adminList--clients__item {
    display: grid;
    grid-template-columns: 3em repeat(4, 1fr) 4em 3em;
}

.adminList__heading, .adminList__column {
    border: 1px solid silver;
    padding: 1em;
}

.adminList__heading {
    border-top: none;
}

.adminList--header {
    font-weight: bold;
}

.adminList__item .adminList__column:first-child, .adminList .adminList__heading:first-child {
    border-left: none;
}

.adminList__item .adminList__column:last-child, .adminList .adminList__heading:last-child {
    border-right: none;
}

.adminList__item:nth-child(even) {
    background: lighten($accent, 46%);
}

.adminList__item:last-of-type, .adminList__item:last-of-type .adminList__column   {
    border-bottom: none;
}

.adminList__column--empty {
    background: lighten(silver, 15%);
}

/* LINKS */

.client__edit {
    color: $black;
    transition: color .5s ease-in-out;
}

.client__edit:hover {
    color: $accent;
}

/* ALIGNMENT */

.adminList__column--center {
    text-align: center;
    padding: 1em 0.5em;
}