details summary::-webkit-details-marker { display: none; }

section#onderhoudAanvragen { margin-top: 50px; }

aside details { margin: 50px 0; }

aside details p, aside details ul { margin: 50px; }

aside details ul { padding-left: 25px; }

aside details ul li:not(:last-child) { margin-bottom: 25px; }

aside details summary {

    display: block;
    list-style: none;
    position: relative;
    outline: none;
    transition: background .5s ease-in-out, color .5s ease-in-out;
}

details[open] summary ~ * { animation: fadeInTop .5s ease-in-out forwards; }
aside details summary h3 { margin: 1em; }

aside details summary:hover {
    background: none;
    cursor: pointer;
    color: $white;
}

aside details summary .fas {
    position: absolute;
    font-size: 3em;
    color: $accent;
    right: 25px;
    transition: text-shadow .5s ease-in-out, color .5s ease-in-out;
    bottom: -27px;
}

aside details[open] .fas {  transform: scaleY(-1); }

aside details summary:hover .fas {
    color: $black;
    text-shadow: -2px 0 $white, 0 2px $white, 2px 0 $white, 0 -2px $white;
}

@media(min-width: 760px) {

    aside#onderhoud details { margin: 50px; }

}