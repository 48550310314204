/* NAV */
nav {
    background: $black;
    height: 75px;
    padding: 15px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 5;
}

nav img.logo {
    width: 100px;
    height: auto;
    margin-top: 5px;
}

nav a.menu {
    color: $white;
    font-size: 2em;
    padding-top: 7px;
    float: right;
}

nav ul, form ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

nav ul {
    margin: 5px -15px;
    display: none;
}

nav ul li a {
    background: $black;
    color: $white;
    border-bottom: 1px solid $white;
    padding: 15px;
    text-align: center;
    display: block;
    font-size: 1.2em;
    text-decoration: none;
    transition: background .5s ease-in-out, color .5s ease-in-out;

    &.active {
        border-left: 5px solid $white;
    }
}

nav ul li:last-child { border-bottom: 0; }

nav ul li a:hover {
    background: $black;
    color: $white;
}

@media(min-width: 760px) {
    nav {
        padding: 0;
    }

    nav div {
        display: flex;
        align-items: center;
    }

    nav a.menu { display: none; }

    nav ul {
        display: block;
        margin: 0 0 0 auto;
    }

    nav ul li {
        display: block;
        float: left;
    }

    nav ul li a {
        background: none;
        color: $white;
        font-size: .9em;
        border-bottom: 0;
        padding: 25px;
        max-height: 75px;

        &.active {
            border-bottom: 5px solid $white;
            border-left: 0;
        }
    }

    nav ul li a:hover {
        background: $white;
        color: $black;
    }

    aside#teaser {
        display: block;
        height: 100vh;
        background: url("../images/content/heroImage.jpg");
        background-size: cover;
        text-align: center;
    }

    aside#teaser img.logo {
        max-width: 40vw;
        height: auto;
        margin-top: 20vh;
    }

    aside#teaser a#scrollDown {
        display: block;
        width: 50px;
        height: 50px;
        color: $black;
        font-size: 3.5em;
        position: absolute;
        bottom: 50px;
        left: 0;
        right: 0;
        margin: 0 auto;
    }

    /* GRIDS */
    @supports (display: grid) {
        
        nav div.container {
            grid-template: 100% / 150px 1fr;
            display: grid;
        }

        .nav--main__list {
            display: grid;
            grid-template: 100% / repeat(4,1fr);
            justify-self: end;
            width: 100%;
            max-width: 615px;
        }

        .nav--main__list--login {
            grid-template: 100% / repeat(5,1fr);
        }

    }

}