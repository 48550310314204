body {
    font: $fontStack;
    color: $black;
    background: $white;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-sanitair:before { content: "\e900";}

.icon-verwarming:before { content: "\e901"; }

.clearfix:after {
    content: "";
    display: table;
    clear: both;
}

.container {
    max-width: 1024px;
    margin: 0 auto;
}

p.container { margin: 1em auto; }

aside#teaser { display: none; }

.shadow {
    -webkit-box-shadow: 3px 6px 30px -9px rgba(0,0,0,0.28);
    -moz-box-shadow: 3px 6px 30px -9px rgba(0,0,0,0.28);
    box-shadow: 3px 6px 30px -9px rgba(0,0,0,0.28);
}

.shadowBox {
    display: none;
    background: rgba(0, 0, 0,.85);
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 4;
}

.shadowBox.show { display: block; }

@media(max-width: 768px) {
    section > p { padding: 0 15px; }
}

@media(min-width: 768px) {
    h2 {
        font-size: 2em;
        margin: 70px 0;
    }
    main div.text { padding: 50px; }
    .container--work {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
}