aside#brands div.container { margin-bottom: 50px; text-align: center; }
aside#brands article {
    width: 150px;
    height: 150px;
    overflow: hidden;
    margin: 0 25px;
    display: inline-block;
}
aside#brands article img {
    filter: grayscale(100%);
    transition: filter .5s ease-in-out;
    width: 100%;
    height: auto;
}
aside#brands article img:hover { filter: none; }