section#work { margin-bottom: 50px; }
section#work article {
    overflow: hidden;
    text-align: center;
}
section#work article img { transition: transform .5s ease-in-out, opacity .5s ease-in-out; }
section#work article:hover img {
    transform: scale(1.2);
    opacity: .4;
}
section#work article img {
    max-width: 343px;
    height: auto;
}

@media(max-width: 768px) {
    section#work article {
        margin-bottom: 1em;
    }
}

@media(min-width: 768px) {
    section#work article {
        width: calc(33.33% - 50px);
        margin: 25px;
    }
}