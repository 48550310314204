form .card { padding: 0; position: relative; z-index: 0; }

form ul { padding: 15px; }

label, input, select, textarea {
    display: block;
    margin: 15px 0 15px 0;
}

label {
    font-weight: bold;
}

label:hover { cursor: pointer; }

form ul li.type { margin-bottom: 15px; }

form ul li.brand, form ul li.subject { display: none; }

input, select, textarea, input[type=radio] + label, .select2-container--default .select2-selection--single {
    border: 2px solid silver;
    padding: 15px;
    border-radius: 3px;
    width: 100%;
    outline: none;
    transition: border .5s ease-in-out, background .5s ease-in-out;
}

.select2-container .select2-selection--single { 
    height: auto!important;
}

textarea { margin-bottom: 0; }

input, textarea, input[type=radio] + label { font-family: "Font Awesome\ 5 Free", "Open Sans"; }

input:focus:not([type=submit]), textarea:focus {
    border: 2px solid $accent;
    background: $white;
}

input.invalid, textarea.invalid, input.invalid:focus, textarea.invalid:focus { border: 2px solid #c0392b; }

input.valid, textarea.valid, input.valid:focus, textarea.valid:focus { border: 2px solid #27ae60; }

input[type=radio] + label {
    width: 33.33%;
    display: block;
    float: left;
    text-align: center;
    margin: 0;
    border-radius: 0;
    padding: 12px;
    overflow: hidden;
    position: relative;
    background: white;
    font-size: .82em;
    transition: background .5s ease-in-out, color .5s ease-in-out;
}

form ul li input[type=radio] + label.first {
    border-right: 0;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

form ul li input[type=radio] + label.last {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-left: 0;
}

input[type=radio]:checked + label, input[type=radio]+ label:hover  { background: none; color: $white; cursor: pointer; }
input[type=radio] { display: none; }
button[type=submit] {
    border: none;
    transition: background .5s ease-in-out;
    position: relative;
    overflow: hidden;
}

a.cta::before, button[type=submit]::before, a .card::before,aside#verwarming details summary::before, aside#onderhoud details summary::before, input[type=radio] + label::before, .admin__nav__link::before {
    background: $accentGradient;
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    z-index: -1;
    left: 0;
    top: 0;
}

a.cta:hover, button[type=submit]:hover, button[type=submit]:focus {
    cursor: pointer;
    background: none;
}

form ul li span {
    text-transform: uppercase;
    font-size: .75em;
    float: right;
    color: silver;
}
textarea { min-height: 170px; }

aside#contactInfo { margin-bottom: 75px; }

aside#contactInfo article { justify-content: center; }

aside#contactInfo article h3 .fas {
    font-size: 3em;
    display: block;
    margin: 0 auto;
    text-align: center;
}

a.cta {
    position: relative;
    overflow: hidden;
    transition: background .5s ease-in-out;
}

a.cta, button[type=submit] {
    background: $black;
    color: $white;
    text-decoration: none;
    display: block;
    border-radius: 5px;
    padding: 15px;
    text-align: center;
    width: 75vw;
    margin: 75px auto;
}

a.cta--inline {
    display: inline;
    padding: 5px;
}

a.cta span {
    display: block;
    font-size: .82em;
}

@media(min-width: 760px) {

    form ul {
        padding: 50px;
        width: 50%;
        float: left;
    }

    a.cta, button[type=submit] { max-width: 250px; }

    aside#contactInfo article { width: 50%; }
    
}