.alert {
    padding: 2em;
    margin: 2em 0;
    background: #bdc3c7;
}

.alert--error {
    background: #e74c3c;
    color: white;
}

.alert--feedback {
    background: #2ecc71;
    color: white;
}