footer {
    background: $black;
    color: $white;
    padding: 15px;
    text-align: center;
}

.footer__socialLinks__link {
    color: $white;
    text-decoration: none;
    font-size: 2em;
}

@media(min-width: 760px) {
    footer {
        text-align: center;
        min-height: 100px;
    }
    
    main.vh { min-height: calc(100vh - 175px); }
}