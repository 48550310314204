.card {
    background: white;
    padding: 15px;
}
a .card {
    position: relative;
    overflow: hidden;
    transition: background .5s ease-in-out;
}
a:hover .card { background: none; }
a .card h3, a .card i.fas, [class^="icon-"], [class*=" icon-"] { color: $black; transition: color .5s ease-in-out; }
a:hover .card h3, a:hover .card i.fas, a:hover .card [class^="icon-"], a:hover .card [class*=" icon-"] { color: $white; }

@media(min-width: 768px) {
    section#services article, section#work article, aside#contactInfo article {
        width: 33.33%;
        display: flex;
        float: left;
        margin-bottom: 75px;
    }
}