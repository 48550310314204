* { box-sizing: border-box; }

/* FONTS */

$fontStack: normal 1.2em/150% 'Open Sans', sans-serif;

@font-face {
    font-family: 'icomoon';
    src:  url('../fonts/icomoon.eot?1hhn1d');
    src:  url('../fonts/icomoon.eot?1hhn1d#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?1hhn1d') format('truetype'),
    url('../fonts/icomoon.woff?1hhn1d') format('woff'),
    url('../fonts/icomoon.svg?1hhn1d#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

/* COLORS */

$black: #333333;
$white: #f4f4f4;
$accent: #14aeec;
$accentGradient: linear-gradient(180deg, #14aeec 50%, #013e9f 100%);

// SOCIAL MEDIA COLORS

$facebook: #3b5998;
$twitter: #00aced;
$itsme: #f44336;

/** MIXINS */

@mixin listReset() {
    list-style-type: none;
    margin: 0;
    padding: 0;
}