/* CONTENT */
.heroImage {
    background: url("../images/content/heroImage.jpg") -50%;
    background-size: cover;
    padding: 15px;
}
.heroImage span, h1, h2 { text-transform: uppercase; }
.heroImage span {
    font-size: .8em;
    background: $black;
    display: inline-block;
    padding: 5px 7px;
    color: $white;
    margin-bottom: 10px;
}
h1 {
    margin: 0;
    line-height: 120%;
}
h2 {
    text-align: center;
    margin: 50px 0;
    line-height: 150%;
}

@media(min-width: 768px) {
    .heroImage {
        height: 300px;
        position: relative;
    }

    .heroImage span { margin-top: 130px; }

    .heroImage div.heroContainer {
        position: absolute;
        bottom: 50px;
        left: 50px;
    }
    
}