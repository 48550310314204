section#recommended { margin-bottom: 100px; }

section#recommended article { position: relative; }

section#recommended article img {
    border-radius: 50%;
    display: block;
    margin: 0 auto;
}

div.quote { margin: 0 0 0 50px; padding-right: 25px; }

div.quote p {
    margin: 25px 0;
    position: relative;
}

div.quote p::before {
    content: "\f10d";
    font-family: "FontAwesome", sans-serif;
    font-size: 2em;
    color: $accent;
    position: absolute;
    left: -50px;
}

@media(min-width: 768px) {
    section#recommended article, .review {
        width: 50%;
        display: flex;
        float: left;
        margin: 75px 0;
    }
    .review { width: calc(50% - 50px); }
}