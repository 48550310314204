section#services article, section#work article {
    height: 200px;
    position: relative;
}
section#services article, section#services article, section#recommended article {
    text-align: center;
    flex-direction: column;
    justify-content: center;
    display: flex;
}
section#services .container a { position: relative; text-decoration: none; }
section#services .container a:nth-child(1), section#work article:nth-child(1), section#recommended article:nth-child(1) { z-index: 2; }
section#services .container a:nth-child(2), section#work article:nth-child(2), section#recommended article:nth-child(2) { z-index: 1; }
section#services article i.fas, [class^="icon-"], [class*=" icon-"] { font-size: 3em; }