.admin__nav {
    @include listReset;
}

.admin__nav__link {
    display: block;
    width: 100%;
    text-decoration: none;
    color: $black;
    text-align: center;
    position: relative;
    font-size: 1.5em;
    padding: 2em;
    transition: background .5s ease-in-out, color .5s ease-in-out;
}

.admin__nav__link:hover {
    background: none;
    color: $white;
}

.admin__nav__link__item__title {
    display: block;
    margin-top: 1em;
}

@media(min-width: 760px) {
    .admin__nav__link {
        width: 50%;
    }
    .admin__nav {
        display: flex;
        flex-wrap: wrap;
    }
}

@media(min-width: 1050px) {
    .admin__nav__link {
        width: 33.33%;
    }
}